import React, { useState } from "react";
import { Col, Container, Row, Toast } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
// import banner from "../../assets/images/aboutUspage/about-us-bg.svg";
import { postData } from "../../api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import phoneNo from '../../assets/images/contanctUspage/phone_icon -contanct.svg';
import mail from '../../assets/images/contanctUspage/mail_icon-contanct.svg';
import location from '../../assets/images/contanctUspage/location_icon-contanct.svg';

function ContactDetail() {
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const handleContactSubmit = async (data) => {
    const url = `${process.env.REACT_APP_BASEURL}/contact/create`;

    const result = await postData(url, data);

    if (result.status) {
      setShow(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <>
       <div className="breatcome_area" >
      {/* <img src={banner} alt="banner"  className="img-fuild"/> */}
      <div className="text_overlay">
    <h1>Contact Us</h1>
    <p>Home || Contact Us</p>
  </div> </div>
      <section>
        <div className="contact_address_area bg_color2 py-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="section_title text-center mb-5">
                  <div className="section_sub_title uppercase mb-3">
                    <h6>CONTACT US</h6>
                  </div>
                  <div className="section_main_title_contact">
                    <h1>We Are Here For You</h1>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={6} sm={12} className="mb-4">
                <div className="single_contact_now">
                  <div className="single_contact_now_inner text-center">
                    <div className="single_contact_now_icon mb-3">
                      <img src={mail} alt="Mail Icon" className="img-fluid" />
                    </div>
                    <div className="single_contact_now_content">
                      <h2 className="mb-3">Mail Here</h2>
                      <p className="m-0" style={{ fontSize: '18px' }}>
                        sales@centillionsoftech.com<br />
                        career@centillionsofttech.com
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6} sm={12} className="mb-4">
                <div className="single_contact_now">
                  <div className="single_contact_now_inner text-center">
                    <div className="single_contact_now_icon mb-3">
                      <img src={location} alt="Location Icon" className="img-fluid" />
                    </div>
                    <div className="single_contact_now_content">
                      <h2 className="mb-3">Visit Here</h2>
                      <p className="m-0" style={{ fontSize: '18px' }}>
                        B-902, The Capital, Science City Road,
                        Ahmedabad, Gujarat, India - 380060
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6} sm={12} className="mb-4 mx-auto">
                <div className="single_contact_now">
                  <div className="single_contact_now_inner text-center">
                    <div className="single_contact_now_icon mb-3">
                      <img src={phoneNo} alt="Phone Icon" className="img-fluid" />
                    </div>
                    <div className="single_contact_now_content">
                      <h2 className="mb-3">Call Us Now</h2>
                      <p className="m-0" style={{ fontSize: '18px' }}>+91-8866531035</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className="toast"
          bg="success"
        >
          <Toast.Body>
            We have received your inquiry. Our team will contact you soon.
          </Toast.Body>
        </Toast>
      </section>
    </>
  );
}

export default ContactDetail;
