import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo_centillion_ from '../../assets/images/centillion_logo/centillion_logo_footer.svg'
function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className=" footer_middle_area pt-25 ">
        <div className=" container">
          <div className=" row" style={{padding:'50px'}}>
         <div className=" col-lg-4 col-md-6 col-sm-12 ">
            <img src={logo_centillion_} alt="logo" style={{filter: 'drop-shadow(5px 5px 5px #222)'}}></img>
              <p className="text">
              Our latest news, articles, and resources, we will sent 
to your inbox weekly.
              </p>
              <div style={{ position: "relative", width: "100%" ,justifyContent: 'space-between',
    display: 'flex'}}>
                <input
                  type="email"
                  placeholder="Enter your email "
                  className="input"
                />
                <button className="bt_subscribe">Subscribe</button>
              </div>
              <ul style={{ listStyle: "none" }} className="d-flex align-items-center pt-40 social-icon-footer">
                  <li className="ps-2 text-white ">
                  <a
                      href="https://www.linkedin.com/company/centillionsoftechpvtltd/mycompany/verification/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" , color:'#fff', justifyContent: 'center',
                      display:' flex' }}
                      className="linkedin"
                    >
                    <i
                      className="fa-brands fa-linkedin-in"
               
                      style={{ margin: "0px 6px 0px 6px", fontSize: "25px"  ,filter: 'drop-shadow(2px 2px 2px #222)' }}
                    ></i>
                    </a>
                  </li>
                  <li className="ps-2 text-white ">
                  <a
                      href="https://www.facebook.com/p/CentillionSoftech-Pvt-Ltd-100071139090027/?_rdr"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" , color:'#fff' ,    justifyContent: 'center',
                      display:' flex'}}
                      className="facebook"
                      
                    >
                    <i
                      className="fa-brands fa-facebook-f"
                      style={{ margin: "0px 6px 0px 6px", fontSize: "21px" ,filter: 'drop-shadow(2px 2px 2px #222)' }}
                    ></i></a>
                  </li>
                  <li className="ps-2 text-white ">
                  <a
                      href="https://www.instagram.com/centillionsoftech?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" , color:'#fff', justifyContent: 'center',
                      display:' flex'}}
                       className="instagram"
                    >
                    <i
                      className="fa-brands fa-instagram"
                      style={{ margin: "0px 6px 0px 6px", fontSize: "25px",filter: 'drop-shadow(2px 2px 2px #222)' }}
                    ></i></a>
                  </li> <li className="ps-2 text-white ">
                  <a
                      href="https://wa.me/8866531035"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color:'#fff',
                        justifyContent: 'center',
                        display:' flex',
                        textDecoration: "none"
                        
                      }}
                       className="whatsapp"
                    >  <i
                      className="fa-brands fa-whatsapp"
                      style={{ margin: "0px 6px 0px 6px", fontSize: "25px" ,filter: 'drop-shadow(2px 2px 2px #222)' }}
                    ></i>
                    </a>
                  </li>
                 
                </ul>

            </div>
            
            <div className=" col-lg-4 col-md-6 col-sm-12 links ">
              <div className="widget widget-nav-menu mt-lg-5 p-0">
                <p className="page">Our pages</p>
                <div className="menu-quick-link-container ml-4">
                  <ul id="menu-quick-link" className="menu">
                    <li>
                      <Link to="/">
                        Home
                        <i
                          className="fa fa-arrow-up"
                          style={{ left: " 10px", position: "relative" }}
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/aboutus">
                        About Us
                        <i
                          className="fa fa-arrow-up"
                          style={{ left: " 10px", position: "relative" }}
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/service">
                        Services
                        <i
                          className="fa fa-arrow-up"
                          style={{ left: " 10px", position: "relative" }}
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/portfolio">
                        Portfolio
                        <i
                          className="fa fa-arrow-up"
                          style={{ left: " 10px", position: "relative" }}
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/gallery">
                        Gallery
                        <i
                          className="fa fa-arrow-up"
                          style={{ left: " 10px", position: "relative" }}
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/career">
                        Career
                        <i
                          className="fa fa-arrow-up"
                          style={{ left: " 10px", position: "relative" }}
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contactus">
                        Contact Us
                        <i
                          className="fa fa-arrow-up"
                          style={{ left: " 10px", position: "relative" }}
                        ></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 col-sm-12 ">
              <div className=" widget widgets-company-info p-0">
                <span className="contact">Contact</span>
                <div className=" footer-social-info ">
                  <p>
                  B-902, The Capital, Science City Road,
                  Ahmedabad, Gujarat, India - 380060
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <div className=" footer-social-info ">
                    
                     
                      <a href="tel:+918866531035" traget="_blank">
                        +91-8866531035
                      </a>
                    
                      
                      <p style={{display:'contents'}}>
                        <a
                          href="mailto:sales@centillionsoftech.com "
                          className="d-flex"
                          traget="_blank"
                        >
                          sales@centillionsoftech.com
                        </a>
                        <a
                          href="mailto:career@centillionsoftech.com "
                          className="d-flex"
                          traget="_blank"
                        >
                          career@centillionsoftech.com
                        </a>
                      </p>
                 
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className=" footer-bottom">
          <div className="container">
           
                <div className=" footer-bottom-content ">
                  <div className=" footer-bottom-content-copy ">
                    <p>
                    © 2024 -Centillion Softech Pvt Ltd .
                    </p>
                  </div>
                </div>
              </div>
              
            
        </div>
      </div>
    </footer>
  );
}

export default Footer;
